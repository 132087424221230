export default {
  name: "PaymentTVirtualAccountPpob",
  data() {
    return {
      identity: {
        virtualAccountId: "",
      },
      dataForm: {
        virtualAccountNumber: "",
        accountName: "",
        officeName: "",
        description: "",
        documentProofNumber: "",
        transactionDate: "",
        paymentTypeId: "",
        billedAccount: "",
        billedName: "",
        billedDescription: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        nominalAdmin: 0,
        nominal: 0,
      },
      options: {
        paymentType: [],
      },
    };
  },
  methods: {
    async getVirtualAccountPpobById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListVirtualAccountPpob();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl:
              "virtual-account-ppob/" + this.identity.virtualAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.virtualAccountNumber =
              resp.data.data.virtualAccountNumber;
            this.dataForm.officeName = resp.data.data.officeName;
            this.dataForm.description = resp.data.data.description;
            this.dataForm.nominal = resp.data.data.nominal;
            this.dataForm.transactionDate = resp.data.data.transactionDate;
            this.dataForm.accountName = resp.data.data.cifIdName;
            this.dataForm.referenceNumber = resp.data.data.referenceNumber;
            this.dataForm.documentProofNumber =
              resp.data.data.documentProofNumber;
            this.dataForm.documentProofNumber =
              resp.data.data.documentProofNumber;
            this.dataForm.paymentTypeId = resp.data.data.paymentTypeId;
            this.dataForm.billedAccount = resp.data.data.billedAccount;
            this.dataForm.billedName = resp.data.data.billedName;
            this.dataForm.nominalAdmin = resp.data.data.nominalAdmin;
            this.dataForm.billedDescription = resp.data.data.billedDescription;
            this.dataForm.createdByUserCode = resp.data.data.createdByUserCode;
            this.dataForm.createdByUserName = resp.data.data.createdByUserName;
            this.dataForm.createdDate = resp.data.data.createdDate;
            this.dataForm.updatedDate = resp.data.data.updatedDate;
            this.dataForm.updatedByUserCode = resp.data.data.updatedByUserCode;
            this.dataForm.updatedByUserName = resp.data.data.updatedByUserName;
            this.dataForm.authorizedDate = resp.data.data.authorizedDate;
            this.dataForm.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.authorizedByUserName =
              resp.data.data.authorizedByUserName;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListVirtualAccountPpob(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListVirtualAccountPpob(),
          });
        }
      }
    },
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem(
        "VIRTUAL_ACCOUNT_NUMBER_DETAIL_PPOB"
      );
      if (!getTokenFromSession) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListVirtualAccountPpob();
        return;
      }
      this.identity.virtualAccountId = getTokenFromSession;
    },
    routeToPageListVirtualAccountPpob() {
      this.$router.push("list");
    },
    async getReferenceVirtualAccountPpob() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "ppob-payment-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.name;
            const value = index.paymentTypeId;
            this.options.paymentType.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getIdentityFromSession();
    this.getReferenceVirtualAccountPpob();
    this.getVirtualAccountPpobById();
  },
};
